.scoutViabilityRow {
  margin-top: 0px !important;
  ::placeholder {
    color: var(--color-aro-gray);
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: var(--color-aro-gray);
  }
}

.scoutViabilityField {
  background: var(--color-linen-white);
}

.scoutViabilityFieldTitleContainer {
  max-height: 22px;
}

.scoutViabilityFieldLabel {
  color: hsl(195.68, 44.9%, 40%);
  font-weight: 700;
  @media (max-width: 1700px) {
    font-size: 14px;
    line-height: 15px;
    margin-bottom:1px;
  }
  @media (max-width: 992px) {
    font-size: 16px;
  }
}
